import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Pound cake`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dvo.com/recipe_pages/agave/Golden_Agave_Pound_Cake.php"
      }}>{`original recipe`}</a></p>
    <p>{`Substitutions:`}</p>
    <ol>
      <li parentName="ol">{`1 cup of agave or maple syrup instead of sugar`}</li>
      <li parentName="ol">{`no pecans/whatever`}</li>
      <li parentName="ol">{`add frozen blueberries but add more flour to compensate`}</li>
    </ol>
    <h2>{`Potato pancakes`}</h2>
    <p>{`Step-by-step:`}</p>
    <ol>
      <li parentName="ol">{`grate several potatoes at small/medium fineness (medium grate size on a regular grater)`}</li>
      <li parentName="ol">{`mix with egg`}</li>
      <li parentName="ol">{`mix with flour -- more flower creates thicker pancakes that are more together, less flour creates a thinner pancake`}</li>
      <li parentName="ol">{`mix in spices: marjoram, salt, oregano, etc. (pepper is great)`}</li>
      <li parentName="ol">{`mix in pressed garlic`}</li>
      <li parentName="ol">{`fry like pancakes (flip when you see edges become brown/fried)`}</li>
    </ol>
    <p>{`Some great ways to mix things up:`}</p>
    <ul>
      <li parentName="ul">{`mix in cheese`}</li>
      <li parentName="ul">{`mix in hot dog or bacon`}</li>
    </ul>
    <h2>{`Pancakes`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.allrecipes.com/recipe/162760/fluffy-pancakes/"
      }}>{`Favorite recipe`}</a></p>
    <h2>{`Vegan Carrot Cake`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.loveandlemons.com/vegan-carrot-cake/"
      }}>{`Easy vegan carrot cake recipe`}</a></p>
    <h2>{`Gluten-Free Sugar Cookies`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.allrecipes.com/recipe/240496/gluten-free-sugar-cookies/"
      }}>{`Gluten-free Sugar Cookies`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      