import React from 'react'

const Media = ({ url = '' }) => {
  if (url.includes('codesandbox')) {
    return (
      <iframe
        src={`${url}?codemirror=1`}
        style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;"
        allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
        sandbox="allow-autoplay allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
      ></iframe>
    )
  } else if (url.includes('codepen')) {
    return <span>Embed not supported yet.</span>
  } else if (url.includes('.png')) {
    return <img src={url} />
  }

  return <span>Embed not supported.</span>
}

export default function Doodle({ url = '' }) {
  if (!url) {
    return null
  }

  return (
    <footer>
      <Media url={url} />
    </footer>
  )
}
