import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Row, Col } from 'react-bootstrap'
import Doodle from '../components/doodle'
import { MDXRenderer } from 'gatsby-plugin-mdx'

export default function PostTemplate({ data, children, pageContext }) {
  const frontmatter = data ? data.mdx.frontmatter : pageContext.frontmatter
  const body = data && data.mdx.body
  const prerenderedBody = !data && children

  const bannerConfig = {
    title: frontmatter.title,
    imgUrl:
      frontmatter.featuredImage && frontmatter.featuredImage !== null
        ? frontmatter.featuredImage.childImageSharp.fluid.src
        : frontmatter.cover_image,
    coverColor: frontmatter.cover_color,
  }

  return (
    <Layout bannerConfig={bannerConfig}>
      <Row>
        <Col sm={8}>
          {body ? <MDXRenderer>{body}</MDXRenderer> : prerenderedBody}
        </Col>
      </Row>
      <Row>
        <Col>
          <Doodle url={frontmatter.doodle_url} />
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
        cover_color
        doodle_url
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
